import { MapView } from "@aws-amplify/ui-react";
import ngeohash from "ngeohash";
import { useEffect, useRef, useState } from "react";
import { Marker, GeolocateControl } from "react-map-gl";
import maplibregl from "maplibre-gl";
import "./App.css";
function App() {
  const [geoNavAvail, setGeoNavAvail] = useState(false);
  const [coords, setCoords] = useState({});
  const mapRef = useRef();

  useEffect(() => {
    setGeoNavAvail("geolocation" in navigator);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        let hash = ngeohash.encode(pos.coords.latitude, pos.coords.longitude);

        let param =
          -1 * new window.URL(window.location.href).searchParams.get("trim");
        console.log(hash);
        hash = param ? hash.slice(0, param) : hash;
        let { longitude: dec_longitude, latitude: dec_latitude } =
          ngeohash.decode(hash);
        console.log(hash);
        setCoords({
          long: pos.coords.longitude,
          lat: pos.coords.latitude,
          hash,
          dec: { dec_longitude, dec_latitude },
        });
        setTimeout(() => {
          let box = ngeohash.decode_bbox(hash);
          new maplibregl.Marker()
            .setLngLat([box[1], box[0]])
            .setPopup(
              new maplibregl.Popup().setHTML(
                `<h1 style="margin:1rem">BOX CORNER 1 !</h1>`
              )
            )
            .addTo(mapRef.current.getMap());

          new maplibregl.Marker()
            .setLngLat([box[3], box[2]])
            .setPopup(
              new maplibregl.Popup().setHTML(
                `<h1 style="margin:1rem">BOX CORNER 2 !</h1>`
              )
            )
            .addTo(mapRef.current.getMap());

          new maplibregl.Marker()
            .setLngLat([dec_longitude, dec_latitude])
            .setPopup(
              new maplibregl.Popup().setHTML(
                `<h1 style="margin:1rem">Rounded !</h1>`
              )
            )
            .addTo(mapRef.current.getMap());
          new maplibregl.Marker()
            .setLngLat([pos.coords.longitude, pos.coords.latitude])
            .setPopup(
              new maplibregl.Popup().setHTML(
                `<h1 style="margin:1rem">GPS!</h1>`
              )
            )
            .addTo(mapRef.current.getMap());
          mapRef.current?.flyTo({
            center: [dec_longitude, dec_latitude],
            zoom: 15,
          });
        }, 2000);
      });
    }
  }, []);

  return (
    <div style={{ overflow: "hidden", width: "100vw", height: "100vh" }}>
      <MapView
        ref={mapRef}
        initialViewState={{
          longitude: 21.011,
          latitude: 52.229,
          zoom: 10,
        }}
      ></MapView>
    </div>
  );
}

export default App;
